import React, { useContext, useEffect, useState } from "react";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import { Card, Modal, ModalBody, Badge } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Row,
  OverlineTitle,
  Sidebar,
  UserAvatar,
} from "../../../../../components/Component";
import { useHistory } from "react-router";
import {
  currentTime,
  findUpper,
  monthNames,
  setDeadlineDays,
  showFormatedDate,
  todaysDate,
} from "../../../../../utils/Utils";
import { UserContext } from "../../../user-manage/UserContext";
import { useDispatch } from "react-redux";
import { exportReport, fetchCurrentUser, fetchProfileData, fetchReport } from "../../../../auth/store/auth.action";
import { useSelector } from "react-redux";
import { selectCurrentUser, selectProfileData, selectReportsList } from "../../../../auth/store/auth.slice";
import ProgLoader from "../../../../components/ProgLoader/ProgLoader";
import { deleteData } from "../../../../../utils/api";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import * as ExcelJS from "exceljs";
import moment from "moment";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { MoonLoader } from "react-spinners";
import { ProfileEditForm } from "../ProfileData/EditProfileForm";

const ShowField = ({ label, field }) => {
  return (
    <div className=" " style={{ width: "100%" }}>
      <div className="d-flex justify-content-between  fs-6">
        <span style={{ width: "100%" }} className=" ">
          {label} :
        </span>
        <span className="fw-bold">{field}</span>
      </div>
    </div>
  );
};
const ReportDetails = ({ match }) => {
  const { contextData } = useContext(UserContext);
  const [data] = contextData;
  const [profileModal, setProfileModal] = useState({
    edit: false,
    add: false,
  });
  const [reportSession, setReportSession] = useState({});
  const onFormCancel = () => {
    setProfileModal({ edit: false, add: false });
  };
  const [sideBar, setSidebar] = useState(false);
  let currentYear = new Date(Date.now()).getFullYear();
  const [selectedProfileData, setSelectedProfileData] = useState({});
  const [open, setOpen] = useState(currentYear);

  const history = useHistory();
  const dispatch = useDispatch();
  const { reports, isLoading, isError } = useSelector(selectReportsList);
  const { profileData, isLoading: profileLoader } = useSelector(selectProfileData);
  const [deleting, setDeleting] = useState(false);

  const sessionId = reports[0]?.reportSessionId;

  const currentUser = useSelector(selectCurrentUser);

  const removeReport = async () => {
    Swal.fire({
      title: "Are you sure? do you want to delete this report ?",
      text: `You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(`/reports/${sessionId}`)
          .then((res) => {
            history.goBack();
          })
          .catch((err) => {});
      }
    });
  };
  let levelObj = reports[0]?.reportSession?.report_level;

  if (reports[0]?.reportSession?.report_level && typeof reports[0]?.reportSession?.report_level === "string") {
    let level = reports[0]?.reportSession?.report_level;
    levelObj = JSON.parse(level);
  }

  const generateExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Data");

    // Add four rows before the data with specified titles, make them bold
    sheet.addRow([`Report From: ${levelObj?.name}`, "", "", ""]).font = {
      bold: true,
      color: { argb: "000000FF" },
    };
    sheet.addRow([`Report Month: ${reports[0]?.reportSession?.month}`, "", "", ""]).font = {
      bold: true,
      color: { argb: "000000FF" },
    };
    sheet.addRow([`Reported At: ${moment(reports[0]?.reportSession?.createdAt).format("LLL")}`, "", "", ""]).font = {
      bold: true,
      color: { argb: "000000FF" },
    };
    sheet.addRow([
      `Reported By: ${reports[0]?.reportSession?.user?.firstName} ${reports[0]?.reportSession?.user?.lastName} (${reports[0]?.reportSession?.user?.email})`,
      "",
      "",
      "",
    ]).font = { bold: true, color: { argb: "000000FF" } };

    // Add headers
    const headers = ["Indicator", "Total"];
    // sheet.addRow(headers);
    const headerRow = sheet.addRow(headers);
    headerRow.font = { bold: true };

    // Add data
    reports?.forEach((item) => {
      sheet.addRow([item.parent.name.trim(), item.total]);
    });

    // Generate buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Create Blob
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = URL.createObjectURL(blob);

    // Create download link
    const link = document.createElement("a");
    link.href = url;
    link.download = `${levelObj?.name}-${moment(reports[0]?.reportSession?.createdAt).format("LLL")} Report.xlsx`;
    document.body.appendChild(link);

    // Trigger download
    link.click();

    // Cleanup
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const toggleAccordion = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  // function to toggle sidebar
  const toggle = () => {
    setSidebar(!sideBar);
  };

  // profile data remover
  const removeProfileDataHandler = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to get this current profile data`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          setDeleting(true);
          deleteData(`/profile-data/${id}`)
            .then((res) => {
              setDeleting(false);
              dispatch(fetchProfileData(currentYear));
              onFormCancel();
            })
            .catch((err) => {
              // console.log(err);
              setDeleting(false);
              onFormCancel();
            });
        }
      })
      .catch((err) => {
        setDeleting(false);
        onFormCancel();
      });
  };

  // edit profile data handler
  const editProfileDataHandler = (data) => {
    setSelectedProfileData(data);
    setProfileModal({ edit: true });
  };

  useEffect(() => {
    const id = match.params.id;
    if (id !== undefined || null || "") {
      dispatch(fetchReport(id));
      dispatch(fetchCurrentUser("df"));
    } else {
    }
  }, [match.params.id, dispatch]);

  // let currentYear = new Date(Date.now()).getFullYear();

  useEffect(() => {
    if (reports?.length > 0) dispatch(fetchProfileData(sessionId));
  }, [dispatch, reports, reportSession]);

  useEffect(() => {
    if (reports?.length > 0) setReportSession(reports[0]?.reportSession);
  }, [dispatch, reports]);

  // console.log(reports);
  return (
    <React.Fragment>
      <Head title="User Details - Regular"></Head>

      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Reports / <strong className="text-primary small">{levelObj?.name}</strong>
              </BlockTitle>
              <BlockDes className="text-soft">
                <ul className="list-inline">
                  <li>
                    Report Month: <span className="text-base">{reportSession?.month}</span>
                  </li>
                  <li>
                    Reported at: <span className="text-base"> {showFormatedDate(reportSession?.reported_at)}</span>
                  </li>
                  {reports[0]?.reportSession?.user?.id == currentUser?.id && (
                    <li>
                      <span onClick={removeReport} style={{ cursor: "pointer" }} className="btn btn-danger ">
                        Clear Report
                      </span>
                    </li>
                  )}
                </ul>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent className={"d-flex align-items-start  gap-3"}>
              {profileLoader ? (
                <Button disabled={true} color="primary">
                  <span>loading ... </span>
                </Button>
              ) : (
                [1, 2, 3].includes(reportSession.level) &&
                profileData?.length != 0 && (
                  <Button color="primary" onClick={() => setProfileModal({ add: true })}>
                    <Icon name="eye"></Icon>
                    <span>View Profile Data</span>
                  </Button>
                )
              )}

              <Button color="light" outline className="bg-white d-none d-sm-inline-flex" onClick={generateExcel}>
                <Icon name="download"></Icon>
                <span>Export</span>
              </Button>
              <Button
                color="light"
                outline
                className="bg-white d-none d-sm-inline-flex"
                onClick={() => history.goBack()}
              >
                <Icon name="arrow-left"></Icon>
                <span>Back</span>
              </Button>
              <a
                href="#back"
                onClick={(ev) => {
                  ev.preventDefault();
                  history.goBack();
                }}
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
              >
                <Icon name="arrow-left"></Icon>
              </a>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered">
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <div className="card-inner">
                  {isLoading && <ProgLoader />}
                  {isError && <span>Something Went wrong, try again later.</span>}
                  <Row>
                    {reports?.map((report, index) => (
                      <Col key={index} xs={12} md={6} className={"mt-5 p-1"}>
                        <Block>
                          <BlockHead>
                            <BlockTitle tag="h6" className="text-base">
                              {report?.parent?.name}
                            </BlockTitle>
                          </BlockHead>
                          <div className="profile-ud-list">
                            <div className="profile-ud-item">
                              <div className="profile-ud row justify-content-between">
                                <span className="profile-ud-label col">Total</span>
                                <span className="fw-bold col">{report?.total}</span>
                              </div>
                            </div>
                          </div>
                        </Block>
                      </Col>
                    ))}
                  </Row>

                  <div className="nk-divider divider md"></div>
                </div>
              </div>
            </div>
          </Card>
        </Block>
      </Content>
      <Modal
        isOpen={profileModal.add}
        toggle={() => setProfileModal({ add: false })}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">
              <span className="text-primary">{levelObj?.name}'s</span>, yearly Profile Data
            </h5>
            <div className="mt-4">
              <Accordion open={open} toggle={toggleAccordion}>
                <AccordionItem>
                  {profileData?.map((item, i) => (
                    <>
                      <AccordionHeader targetId={item.year}>{item.year}</AccordionHeader>
                      <AccordionBody accordionId={item.year}>
                        <Row className={"p-3"}>
                          <Col md={6}>
                            <ShowField label={"Total Population"} field={item?.total_population} />
                            <ShowField label={"Total House Hold"} field={item?.total_house_holder} />
                            <ShowField label={"Total Kebele"} field={item?.total_kebele} />
                            <ShowField label={"Total Health Post"} field={item?.total_health_post} />
                            <ShowField label={"Total Health Center"} field={item?.total_health_center} />
                            <ShowField label={"Total Hospitals"} field={item?.total_hospital} />
                          </Col>
                          <Col md={6}>
                            <ShowField label={"Total Private Health Center"} field={item?.total_private_hc} />
                            <ShowField label={"Total Private Clinic"} field={item?.total_private_clinic} />
                            <ShowField label={"Total Private Hospital"} field={item?.total_private_hospital} />
                            <ShowField label={"Total Primary School"} field={item?.total_primary_school} />
                            <ShowField label={"Total Secondary School"} field={item?.total_secondary_school} />
                          </Col>
                        </Row>
                        {item.userId === currentUser?.id && (
                          <div className="d-flex justify-content-between bg-light">
                            <Button color="primary" onClick={() => editProfileDataHandler(item)}>
                              {<FaRegEdit />}
                            </Button>
                            <Button
                              color="danger"
                              disabled={deleting}
                              onClick={() => removeProfileDataHandler(item.id)}
                            >
                              {<RiDeleteBinLine />}
                            </Button>
                          </div>
                        )}
                      </AccordionBody>
                    </>
                  ))}
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={profileModal.edit}
        toggle={() => setProfileModal({ edit: false })}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">
              Update<span className="text-primary"> {levelObj?.name}'s</span>, Profile Data
            </h5>
            <div className="mt-4">
              <ProfileEditForm
                selectedProfileData={selectedProfileData}
                setSelectedProfileData={setSelectedProfileData}
                setProfileModal={setProfileModal}
                sessionId={sessionId}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default ReportDetails;
