import React, { useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal, ModalBody, DropdownItem, Form } from "reactstrap";
import _ from "lodash";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  RSelect,
  OrderTable,
} from "../../../../components/Component";
import { filterRole, filterStatus } from ".././UserData";
import { bulkActionOptions, findUpper } from "../../../../utils/Utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectAllIndicators, selectIndicatorsList } from "../../../auth/store/auth.slice";

import { fetchAllIndicatorsList, fetchIndicatorsList } from "../../../auth/store/auth.action";
import { PropagateLoader } from "react-spinners";
import { postData, updateData } from "../../../../utils/api";
import IndicatorForm from "./IndicatorForm";
import Actions from "./Actions";

const IndicatorList = () => {
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useSelector(selectAllIndicators);

  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [open, setOpen] = useState(false);
  const [newly, setNewly] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  const reloadData = () => {
    setTimeout(() => {
      dispatch(fetchAllIndicatorsList({ currentPage, all: false }));
    }, 0);
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    dispatch(fetchAllIndicatorsList({ currentPage, all: false }));
  }, [dispatch, currentPage]);

  const editHandler = (item) => {
    setSelectedData(item);
    setNewly(false);
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Head title="User List - Regular"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Indicators Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total Indicators.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setOpen(true)}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          {/* Loading Component */}
          <>
            {isLoading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="p-2"></span>
                <span>
                  <PropagateLoader className="text-center" color="#3693d6" />
                </span>
              </div>
            )}
            {isError && <span className="text-danger">Something went wrong, try again</span>}
          </>
          <DataTable className="card-stretch">
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">S.N.</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Name</span>
                </DataTableRow>

                <DataTableRow size="md">
                  <span className="sub-text">Registered at</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text"> Actions</span>
                </DataTableRow>
              </DataTableHead>

              {/* rendering component */}
              {data?.length > 0
                ? data?.map((item, index) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow size="mb">
                          <span className="tb-amount">{index + 1}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="user-card">
                            <UserAvatar
                              theme={item.avatarBg}
                              text={findUpper(item.name)}
                              image={item.image}
                            ></UserAvatar>
                            <div className="user-info">
                              <span className="tb-lead">
                                {item.name}{" "}
                                <span
                                  className={`dot dot-${
                                    item.status === "Active"
                                      ? "success"
                                      : item.status === "Pending"
                                      ? "warning"
                                      : "danger"
                                  } d-md-none ms-1`}
                                ></span>
                              </span>
                            </div>
                          </div>
                        </DataTableRow>

                        <DataTableRow size="mb">
                          <span className="tb-amount">
                            {item.createdAt} <span className="currency"></span>
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <Actions
                            editHandler={editHandler}
                            item={item}
                            reloadData={reloadData}
                            setLoading={setLoading}
                            url={"indicators"}
                          />
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {/* {zones?.totalItems > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={zones?.totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                !isLoading && (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )
              )} */}
            </div>
          </DataTable>
        </Block>
        <IndicatorForm
          setNewly={setNewly}
          setSelectedData={setSelectedData}
          newly={newly}
          selectedData={selectedData}
          open={open}
          setOpen={setOpen}
        />
      </Content>
    </React.Fragment>
  );
};
export default IndicatorList;
