import React, { useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import SaleRevenue from "../../components/partials/default/sale-revenue/SaleRevenue";
import ActiveSubscription from "../../components/partials/default/active-subscription/ActiveSubscription";
import AvgSubscription from "../../components/partials/default/avg-subscription/AvgSubscription";
import SalesOverview from "../../components/partials/default/sales-overview/SalesOverview";
import TransactionTable from "../../components/partials/default/transaction/Transaction";
import RecentActivity from "../../components/partials/default/recent-activity/Activity";
import NewsUsers from "../../components/partials/default/new-users/User";
import Support from "../../components/partials/default/support-request/Support";
import Notifications from "../../components/partials/default/notification/Notification";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewCard,
  PreviewAltCard,
  BlockBetween,
  LineChartExample,
  BarChartExample,
} from "../../components/Component";
import { barChartData, solidLineChart } from "../components/charts/ChartData";
import { getData } from "../../utils/api";
import { useSelector } from "react-redux";
import { fetchDashboard } from "../auth/store/auth.action";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { selectDashboardData } from "../auth/store/auth.slice";
import YearSelector from "./YearSelector";
import ChartOne from "./ChartOne";
import ChartTwo from "./ChartTwo";

const Homepage = () => {
  const [sm, updateSm] = useState(false);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const onChange = (selectedYear) => {
    setSelectedYear(selectedYear);
  };

  const chartData = [
    {
      title: "Performance review meeting conducted on SBC",
      id: 35,
    },
    {
      title: "Number of schools with functional health clubs",
      id: 27,
    },
    {
      title: "Number of clients received counseling services",
      id: 9,
    },
    {
      title: "Community conversation conducted",
      id: 25,
    },
  ];
  return (
    <React.Fragment>
      <Head title="Homepage"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Welcome to Oromia Health Bureau SBC Dashboard
              </BlockTitle>
            </BlockHeadContent>
            <YearSelector selectedYear={selectedYear} onChange={onChange} />
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}></div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h4">Annually Report</BlockTitle>
              {/* <BlockDes>
                <p>Description here</p>
              </BlockDes> */}
            </BlockHeadContent>
          </BlockHead>
          <Row className="g-gs">
            {chartData?.map((data) => (
              <ChartOne title={data.title} currentYear={selectedYear} parentId={data.id} />
            ))}

            {/* {chartData.map((chart) => (
              <Col md={6}>
                <PreviewCard>
                  <div className="card-head">
                    <h6 className="title">{chart.title}</h6>
                  </div>
                  <div className="nk-ck-sm">
                    <BarChartExample data={chart.data} />
                  </div>
                </PreviewCard>
              </Col>
            ))} */}
          </Row>
        </Block>
        <Block>
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h4">Annually Report</BlockTitle>
              {/* <BlockDes>
                <p>Description here</p>
              </BlockDes> */}
            </BlockHeadContent>
          </BlockHead>
          <ChartTwo currentYear={selectedYear} title={"SBC materials produced / received and distributed"} />
          {/* <PreviewCard>
            <div className="nk-ck">
              <LineChartExample legend={true} data={solidLineChart} />
            </div>
          </PreviewCard> */}
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Homepage;
