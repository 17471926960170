import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Spinner } from "reactstrap";
import { useDispatch } from "react-redux";
import { getData, postData } from "../../utils/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [passState2, setPassState2] = useState(false);
  const [hash, setHash] = useState("");
  const [success, setSuccess] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const { token } = useParams("token");

  const [errorVal, setError] = useState("");

  const dispatch = useDispatch();

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm();

  useEffect(() => {
    const checkToken = async (token) => {
      try {
        setInitializing(true);
        let response = await getData(`/verify-token/${token}`);
        if (response.status === 200) {
          setInitializing(false);
          setSuccess(true);
        }
      } catch (error) {
        setSuccess(false);
        setInitializing(false);
      }
    };

    checkToken(token);
  }, [token]);

  const onFormSubmit = async (formData) => {
    setLoading(true);
    // console.log(formData);

    await postData(`/reset-password/${token}`, {
      password: formData.passcode,
      passwordConfirm: formData.confirmPassword,
    })
      .then((data) => {
        setTimeout(() => {
          window.history.pushState(
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
            "auth-login",
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
          );
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
        setTimeout(() => {
          setError("Cannot reset password");
          setLoading(false);
        }, 2000);
      });
  };

  return (
    <React.Fragment>
      <Head title="Forgot-Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            {initializing ? (
              <div className="text-center fs-5">Loading...</div>
            ) : success ? (
              <>
                <BlockHead>
                  <BlockContent>
                    <BlockTitle tag="h5">Reset password</BlockTitle>
                    <BlockDes>
                      <p>
                        Please Insert your new passcode and confirm it. Make sure you remember it, you will need it to
                        login.
                      </p>
                    </BlockDes>
                  </BlockContent>
                </BlockHead>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="password">
                        New Passcode
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <a
                        href="#password"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPassState(!passState);
                        }}
                        className={`form-icon lg form-icon-right passcode-switch ${
                          passState ? "is-hidden" : "is-shown"
                        }`}
                      >
                        <Icon name="eye" className="passcode-icon icon-show"></Icon>

                        <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                      </a>
                      <input
                        type={passState ? "text" : "password"}
                        id="password"
                        name="passcode"
                        {...register("passcode", { required: "This field is required" })}
                        placeholder="Enter your passcode"
                        className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                      />
                      {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="confirmPassword">
                        Comfirm Passcode
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <a
                        href="#confirmPassword"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPassState2(!passState2);
                        }}
                        className={`form-icon lg form-icon-right passcode-switch ${
                          passState2 ? "is-hidden" : "is-shown"
                        }`}
                      >
                        <Icon name="eye" className="passcode-icon icon-show"></Icon>

                        <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                      </a>
                      <input
                        type={passState2 ? "text" : "password"}
                        id="confirmPassword"
                        name="confirmPassword"
                        {...register("confirmPassword", { required: "This field is required" })}
                        placeholder="Comfirm your passcode"
                        className={`form-control-lg form-control ${passState2 ? "is-hidden" : "is-shown"}`}
                      />
                      {errors.confirmPassword && <span className="invalid">{errors.confirmPassword.message}</span>}
                    </div>
                  </div>
                  <div className="form-group">
                    <Button size="lg" className="btn-block" type="submit" color="primary">
                      {loading ? <Spinner size="sm" color="light" /> : "Reset Password"}
                    </Button>
                  </div>
                </Form>
              </>
            ) : (
              <BlockHead>
                <BlockContent>
                  <BlockTitle className={"text-danger"} tag="h5">
                    Invalid Token or Expired.
                  </BlockTitle>
                  <BlockDes>
                    <p>
                      Please try to send password reset request again, if you think this is a problem contact your
                      system admin.{" "}
                    </p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
            )}

            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Return to login</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};

export default ResetPassword;
