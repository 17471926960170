import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { BarChartExample, PreviewCard } from "../../components/Component";
import { getData } from "../../utils/api";
import { barChartData, solidLineChart } from "../components/charts/ChartData";

const ChartOne = ({ currentYear, parentId, title }) => {
  const [chart, setChart] = useState(barChartData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  //  barChartData.labels = data[0]?.labels;
  //  barChartData.datasets[0].data = data[0]?.data;

  let getDashboardData = async () => {
    try {
      setIsLoading(true);
      let result = await getData(`/dashboard/${parentId}/${currentYear}`);
      let data = result?.data?.report[0];

      setChart((prev) => {
        return {
          ...prev,
          labels: data?.labels,

          datasets: [
            {
              label: "Total",
              backgroundColor: "#1a9ed9",
              barPercentage: 0.8,
              categoryPercentage: 0.8,
              data: data?.data,
            },
          ],
        };
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, [currentYear, parentId]);

  return (
    <Col md={6}>
      <PreviewCard>
        <div className="card-head">
          <h6 className="title">{title}</h6>
        </div>
        {isLoading ? (
          <div className="nk-ck-sm d-flex align-items-center justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="nk-ck-sm">
            <BarChartExample data={chart} />
          </div>
        )}
      </PreviewCard>
    </Col>
  );
};

export default ChartOne;
