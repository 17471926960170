import React, { useState } from "react";
import { Button, Col, Form, Modal, ModalBody } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { fetchAllIndicatorsList } from "../../../auth/store/auth.action";
import { postData, updateData } from "../../../../utils/api";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { indicatorSchema } from "./schema";

const IndicatorForm = ({ setSelectedData, open, setOpen, newly, selectedData, setNewly }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const dispatch = useDispatch();

  const { handleSubmit, values, handleBlur, handleChange, handleReset, resetForm, touched, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedData?.name || "", // string
      description: selectedData?.description || "", // string
      // indicator levels
      is_woreda_level: selectedData?.is_woreda_level || false,
      is_zone_level: selectedData?.is_zone_level || false,
      is_region_level: selectedData?.is_region_level || false,
      is_hf_level: selectedData?.is_hf_level || false,
      is_community_level: selectedData?.is_community_level || false,
    },

    onSubmit: async (values) => {
      // convert string to boolean values
      const convertedValues = convertStringToBoolean(values);

      if (newly) {
        const result = await postData(`/indicators`, convertedValues);
        if (result?.data?.status === false) {
          setErrorArray(result?.data?.errors);
          setIsLoading(false);
          return;
        }
        resetForm();
        setIsLoading(false);
        reloadData();
        onFormCancel();
      } else {
        setIsLoading(true);
        const result = await updateData(`/indicators/${selectedData.id}`, convertedValues);

        if (result?.data?.status === false) {
          setErrorArray(result?.data?.errors);
          setIsLoading(false);
          return;
        }
        resetForm();
        setIsLoading(false);
        reloadData();
        onFormCancel();
      }
    },
    validationSchema: indicatorSchema,
  });
  const onFormCancel = () => {
    setOpen(false);
    setNewly(true);
    // resetting the form
    resetForm();
    setSelectedData({});
  };
  const reloadData = () => {
    setTimeout(() => {
      //   dispatch(fetchAllIndicatorsList({ currentPage, all: false }));
      dispatch(fetchAllIndicatorsList());
    }, 0);
  };

  return (
    <div>
      <Modal isOpen={open} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#close"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title"> {newly ? "Add New Indicator" : "Edit Indicator"}</h5>
            <div className="d-flex flex-column justify-content-end align-items-end">
              {errorArray?.length > 0 && (
                <div className="alert alert-danger">
                  <ul>
                    {errorArray.map((error, index) => {
                      return <li key={index}>{error}</li>;
                    })}
                  </ul>
                </div>
              )}
            </div>
            <div className="mt-4">
              <Form className="row gy-4" noValidate onSubmit={handleSubmit}>
                {/* name */}
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label">Indicator Name</label>
                    <input
                      className={`form-control ${touched.name && errors.name ? "border-danger" : ""}`}
                      type="text"
                      name="name"
                      placeholder="Enter name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.name && <span className="invalid">{errors.name}</span>}
                  </div>
                </Col>
                {/* session */}
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label"> Indicator Descriptions</label>
                    <div>( optional )</div>
                    <textarea
                      rows={4}
                      className="form-control"
                      type="text"
                      name="description"
                      placeholder="Enter Indicator explanations ..."
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.description && <span className="invalid">{errors.description}</span>}
                  </div>
                </Col>

                <div>Indicator Availabilities</div>
                {/* region */}
                <Col md="4">
                  <div className="form-group">
                    <label className="form-label">Available in Region ?</label>
                    <select
                      className="form-control"
                      name="is_region_level"
                      defaultValue={values.is_region_level}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.is_region_level && <span className="invalid">{errors.is_region_level}</span>}
                  </div>
                </Col>
                {/* zone */}
                <Col md="4">
                  <div className="form-group">
                    <label className="form-label">Available in Zone ?</label>
                    <select
                      className="form-control"
                      name="is_zone_level"
                      defaultValue={values.is_zone_level}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.is_zone_level && <span className="invalid">{errors.is_zone_level}</span>}
                  </div>
                </Col>
                {/* woreda */}
                <Col md="4">
                  <div className="form-group">
                    <label className="form-label">Available in Woreda ?</label>
                    <select
                      className="form-control"
                      name="is_woreda_level"
                      defaultValue={values.is_woreda_level}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.is_woreda_level && <span className="invalid">{errors.is_woreda_level}</span>}
                  </div>
                </Col>
                {/* hf */}
                <Col md="4">
                  <div className="form-group">
                    <label className="form-label">Available in HF ?</label>
                    <select
                      className="form-control"
                      name="is_hf_level"
                      defaultValue={values.is_hf_level}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.is_hf_level && <span className="invalid">{errors.is_hf_level}</span>}
                  </div>
                </Col>
                {/* community */}
                <Col md="4">
                  <div className="form-group">
                    <label className="form-label">Available in Community ?</label>
                    <select
                      className="form-control"
                      name="is_community_level"
                      defaultValue={values.is_community_level}
                      value={values.is_community_level}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    {touched.is_community_level && <span className="invalid">{errors.is_community_level}</span>}
                  </div>
                </Col>
                {/* button */}
                <div className="d-flex justify-content-end ">
                  {/* <Col size="12"> */}
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormCancel();
                        }}
                        className="link link-danger "
                      >
                        Cancel
                      </a>
                    </li>
                    {newly ? (
                      <li>
                        <Button disabled={isLoading} color="primary" size="md" type="submit">
                          {isLoading ? "Adding ..." : "Add Indicator"}
                        </Button>
                      </li>
                    ) : (
                      <li>
                        <Button disabled={isLoading} color="primary" size="md" type="submit">
                          {isLoading ? "Updating ..." : "Update Indicator"}
                        </Button>
                      </li>
                    )}
                  </ul>
                  {/* </Col> */}
                </div>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

function convertStringToBoolean(value) {
  if (typeof value.has_session === "string") {
    value.has_session = value.has_session === "true";
  }
  if (typeof value.has_gender === "string") {
    value.has_gender = value.has_gender === "true";
  }
  if (typeof value.has_age_category === "string") {
    value.has_age_category = value.has_age_category === "true";
  }
  if (typeof value.has_number_of_distributed === "string") {
    value.has_number_of_distributed = value.has_number_of_distributed === "true";
  }
  if (typeof value.has_reach === "string") {
    value.has_reach = value.has_reach === "true";
  }
  if (typeof value.has_estimated_cost === "string") {
    value.has_estimated_cost = value.has_estimated_cost === "true";
  }
  if (typeof value.has_negative_positive === "string") {
    value.has_negative_positive = value.has_negative_positive === "true";
  }
  if (typeof value.is_woreda_level === "string") {
    value.is_woreda_level = value.is_woreda_level === "true";
  }
  if (typeof value.is_zone_level === "string") {
    value.is_zone_level = value.is_zone_level === "true";
  }
  if (typeof value.is_region_level === "string") {
    value.is_region_level = value.is_region_level === "true";
  }
  if (typeof value.is_hf_level === "string") {
    value.is_hf_level = value.is_hf_level === "true";
  }
  if (typeof value.is_community_level === "string") {
    value.is_community_level = value.is_community_level === "true";
  }

  return value;
}

export default IndicatorForm;
