import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Row } from "reactstrap";
import { fetchProfileData, saveProfileReport } from "../../../../auth/store/auth.action";
import { useDispatch } from "react-redux";
import { getData, updateData } from "../../../../../utils/api";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../auth/store/auth.slice";
import { TbSum } from "react-icons/tb";
import { monthList } from "../../ProjectData";
import * as ExcelJS from "exceljs";
import moment from "moment";
import { Icon } from "../../../../../components/Component";

export const AggregateForm = ({ level, setIsAggregated }) => {
  const user = useSelector(selectCurrentUser);
  const [errorsArray, setErrorsArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //   month, year
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [filteredMonthList, setFilteredMonthList] = useState([]);
  const [aggregatedReport, setAggregatedReport] = useState([]);
  const [isAggregationEmpty, setIsAggregationEmpty] = useState(false);

  const dispatch = useDispatch();

  const years = Array.from({ length: currentYear - 2023 }, (_, index) => currentYear - index).map((year) => ({
    value: year,
    label: year,
  }));

  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm();

  const submitForm = async (data) => {
    const { year, type, month } = data;
    setIsLoading(true);
    // localhost:4000/aggregate/2024/annually/2
    let url;
    if (type === "monthly") {
      url = `/aggregate/${year}/${type}/${level}?month=${month}`;
    } else {
      url = `/aggregate/${year}/${type}/${level}`;
    }
    try {
      let result = await getData(url);
      if (result.data?.report?.length > 0) {
        // put the data on the state for exporting
        setAggregatedReport(result.data);
        setIsAggregationEmpty(false);
        setIsLoading(false);
      } else {
        setIsAggregationEmpty(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setAggregatedReport([]);
      setIsAggregationEmpty(false);
    }
  };

  const generateExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Data");
    let name = level == 1 ? "Reginal" : level == 2 ? "Zone" : level == 3 ? "Woreda" : "Hospital";
    // Add four rows before the data with specified titles, make them bold
    sheet.addRow([`Aggregated ${name} Report`, "", "", ""]).font = {
      bold: true,
      color: { argb: "000000FF" },
    };
    if (aggregatedReport.type == "monthly") {
      sheet.addRow([`Report Type: ${aggregatedReport.type} (${aggregatedReport.month})`, "", "", ""]).font = {
        bold: true,
        color: { argb: "000000FF" },
      };
    } else {
      sheet.addRow([`Report Type: ${aggregatedReport.type}`, "", "", ""]).font = {
        bold: true,
        color: { argb: "000000FF" },
      };
    }

    sheet.addRow([`Report Year: ${aggregatedReport.year}`, "", "", ""]).font = {
      bold: true,
      color: { argb: "000000FF" },
    };

    // Add headers
    const headers = ["Indicator", "Total"];
    // sheet.addRow(headers);
    const headerRow = sheet.addRow(headers);
    headerRow.font = { bold: true };

    // Add data
    aggregatedReport?.report?.forEach((item) => {
      sheet.addRow([item.indicatorName, item.total_sum]);
    });

    // Generate buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Create Blob
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = URL.createObjectURL(blob);

    // Create download link
    const link = document.createElement("a");
    link.href = url;
    if (aggregatedReport.type == "monthly") {
      link.download = `Aggregated ${name}-${aggregatedReport.type} ${aggregatedReport.year} (${aggregatedReport.month}) Report.xlsx`;
    } else {
      link.download = `Aggregated ${name}-${aggregatedReport.type} ${aggregatedReport.year} Report.xlsx`;
    }

    document.body.appendChild(link);

    // Trigger download
    link.click();

    // Cleanup
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  useEffect(() => {
    const currentMonth = new Date().getMonth();
    if (watch("year") == currentYear) {
      const filteredMonths = monthList.filter((month, index) => index <= currentMonth);
      setFilteredMonthList(filteredMonths);
    } else {
      setFilteredMonthList(monthList);
    }
  }, [currentYear]);

  useEffect(() => {
    setAggregatedReport([]);
  }, [watch("year"), watch("type"), watch("month")]);

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      {errorsArray.length > 0 && (
        <div className="alert alert-danger">
          <ul>
            {errorsArray.map((error, index) => {
              return <li key={index}>{error}</li>;
            })}
          </ul>
        </div>
      )}
      <Row className="gy-4 mb-3">
        {/* aggregation year */}
        <Col md="4">
          <div className="form-group">
            <label className="form-label" htmlFor="year">
              Select Year <span className="text-primary"> ({watch("year")})</span>
            </label>
            <div className="form-control-wrap">
              <select className="form-control" id="year" name="year" {...register("year", { required: true })}>
                {years?.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              {errors.year && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        {/* aggregation type */}
        <Col md="4">
          <div className="form-group">
            <label className="form-label" htmlFor="type">
              Choose Period <span className="text-primary"> ({watch("type")})</span>
            </label>
            <div className="form-control-wrap">
              <select className="form-control" id="type" name="type" {...register("type", { required: true })}>
                {["monthly", "quarterly", "mid-annual", "annually"].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              {errors.type && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        {/* moth */}
        {watch("type") === "monthly" && (
          <Col md="4">
            <div className="form-group">
              <label className="form-label" htmlFor="month">
                Month List <span className="text-primary"> ({watch("month")})</span>
              </label>
              <div className="form-control-wrap">
                <select className="form-control" id="month" name="month" {...register("month", { required: true })}>
                  {filteredMonthList.map((item, index) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                {errors.month && <span className="invalid">This field is required</span>}
              </div>
            </div>
          </Col>
        )}
      </Row>
      {aggregatedReport?.report?.length > 0 && (
        <div className="d-flex justify-content-between items-center">
          <div className="d-flex flex-column">
            <span className="text-success">Aggregated Data Available</span>
            <small className="font-bold"> Report : {aggregatedReport?.type?.toUpperCase()} </small>
            <small className="font-bold"> Year : {aggregatedReport?.year} </small>
          </div>
          <Button color="primary" outline className="d-none d-sm-inline-flex" onClick={generateExcel}>
            <Icon name="download"></Icon>
            <span>Export Aggregated Data</span>
          </Button>
        </div>
      )}
      {isAggregationEmpty && (
        <div className="pb-3 d-flex align-items-center justify-content-end text-danger">
          <Icon name="info"></Icon> <span>No Reports found, to Aggregate</span>
        </div>
      )}

      {!(aggregatedReport?.report?.length > 0) && (
        <div className="d-flex justify-content-end">
          <Button disabled={isLoading} className={""} color="success" type="submit">
            <span>
              {isLoading && <span className="spinner-border  spinner-border-sm me-3" role="status"></span>}
              Aggregate
            </span>
          </Button>
        </div>
      )}
    </form>
  );
};
