import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Row } from "reactstrap";
import { fetchCurrentProfileData, fetchProfileData, saveProfileReport } from "../../../../auth/store/auth.action";
import { useDispatch } from "react-redux";
import { refresh } from "../../../../../utils/Utils";
import { postData } from "../../../../../utils/api";
import { set } from "lodash";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../auth/store/auth.slice";

export const InputField = (label, name, register, errors) => {
  return (
    <Col md="4">
      <div className="form-group">
        <label className="form-label" htmlFor={name}>
          {label}
        </label>
        <div className="form-control-wrap">
          <input
            type="number"
            id={name}
            {...register(name, { required: true })}
            className="form-control"
            name={name}
            min="0"
          />
          {errors[name] && <span className="invalid">This field is required</span>}
        </div>
      </div>
    </Col>
  );
};
export const ProfileForm = ({ level, url, setProfileModal }) => {
  const user = useSelector(selectCurrentUser);
  const [errorsArray, setErrorsArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();

  let currentYear = new Date(Date.now()).getFullYear();

  const submitForm = async (data) => {
    // setting the level id and createdAt
    data.level_id =
      level == 1
        ? user?.region?.id
        : level == 2
        ? user?.zone?.id
        : level == 3
        ? user?.woreda?.id
        : level == 4
        ? user?.healthFacility?.id
        : user?.community?.id;
    data.year = new Date(Date.now()).getFullYear();
    data.userId = user?.id;

    setIsLoading(true);
    postData("/profile-data", { ...data, level })
      .then((res) => {
        if (res.status === 422) {
          return setErrorsArray(res.data.errors);
        }
        setTimeout(() => {
          dispatch(fetchCurrentProfileData({ year: currentYear, level: user?.level, level_id: data.level_id }));
        }, 0);
        setIsLoading(false);
        setProfileModal({ add: false, edit: false });
      })
      .catch((err) => {
        setIsLoading(false);
        // setProfileData({ add: false, edit: false });
      });
  };

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      {errorsArray.length > 0 && (
        <div className="alert alert-danger">
          <ul>
            {errorsArray.map((error, index) => {
              return <li key={index}>{error}</li>;
            })}
          </ul>
        </div>
      )}
      <Row className="gy-4">
        <Col md="4">
          <div className="form-group">
            <label className="form-label" htmlFor={"total_population"}>
              Total Population
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id={"total_population"}
                {...register("total_population", { required: true })}
                className="form-control"
                name={"total_population"}
                min="0"
              />
              {errors["total_population"] && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="4">
          <div className="form-group">
            <label className="form-label" htmlFor={"total_house_holder"}>
              Total House Hold
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id={"total_house_holder"}
                {...register("total_house_holder", { required: true })}
                className="form-control"
                name={"total_house_holder"}
                min="0"
              />
              {errors["total_house_holder"] && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="4">
          <div className="form-group">
            <label className="form-label" htmlFor={"total_kebele"}>
              Total Kebele
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id={"total_kebele"}
                {...register("total_kebele", { required: true })}
                className="form-control"
                name={"total_kebele"}
                min="0"
              />
              {errors["total_kebele"] && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="4">
          <div className="form-group">
            <label className="form-label" htmlFor={"total_health_post"}>
              Total Health Post
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id={"total_health_post"}
                {...register("total_health_post", { required: true })}
                className="form-control"
                name={"total_health_post"}
                min="0"
              />
              {errors["total_health_post"] && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="4">
          <div className="form-group">
            <label className="form-label" htmlFor={"total_health_center"}>
              Total Health Center
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id={"total_health_center"}
                {...register("total_health_center", { required: true })}
                className="form-control"
                name={"total_health_center"}
                min="0"
              />
              {errors["total_health_center"] && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>

        <Col md="4">
          <div className="form-group">
            <label className="form-label" htmlFor={"total_hospital"}>
              Total Hospitals
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id={"total_hospital"}
                {...register("total_hospital", { required: true })}
                className="form-control"
                name={"total_hospital"}
                min="0"
              />
              {errors["total_hospital"] && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>

        <Col md="4">
          <div className="form-group">
            <label className="form-label" htmlFor={"total_private_hc"}>
              Total Private Health Center
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id={"total_private_hc"}
                {...register("total_private_hc", { required: true })}
                className="form-control"
                name={"total_private_hc"}
                min="0"
              />
              {errors["total_private_hc"] && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="4">
          <div className="form-group">
            <label className="form-label" htmlFor={"total_private_clinic"}>
              Total Private Clinic
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id={"total_private_clinic"}
                {...register("total_private_clinic", { required: true })}
                className="form-control"
                name={"total_private_clinic"}
                min="0"
              />
              {errors["total_private_clinic"] && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="4">
          <div className="form-group">
            <label className="form-label" htmlFor={"total_private_hospital"}>
              Total Private Hospital
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id={"total_private_hospital"}
                {...register("total_private_hospital", { required: true })}
                className="form-control"
                name={"total_private_hospital"}
                min="0"
              />
              {errors["total_private_hospital"] && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="4">
          <div className="form-group">
            <label className="form-label" htmlFor={"total_primary_school"}>
              Total Primary School
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id={"total_primary_school"}
                {...register("total_primary_school", { required: true })}
                className="form-control"
                name={"total_primary_school"}
                min="0"
              />
              {errors["total_primary_school"] && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
        <Col md="4">
          <div className="form-group">
            <label className="form-label" htmlFor={"total_secondary_school"}>
              Total Secondary School
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id={"total_secondary_school"}
                {...register("total_secondary_school", { required: true })}
                className="form-control"
                name={"total_secondary_school"}
                min="0"
              />
              {errors["total_secondary_school"] && <span className="invalid">This field is required</span>}
            </div>
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <Button disabled={isLoading} className={"mt-5"} color="primary" type="submit">
          {isLoading && <span className="spinner-border  spinner-border-sm me-3" role="status"></span>}
          Save
        </Button>
      </div>
    </form>
  );
};
