import React, { useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert, Form, Spinner } from "reactstrap";
import { useDispatch } from "react-redux";
import { postData } from "../../utils/api";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setErrorVal] = useState("");
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();
  const {
    formState: { errors },
    reset,
    register,
    handleSubmit,
    setError,
  } = useForm();

  const onFormSubmit = async (formData) => {
    setLoading(true);

    await postData("/forgot-password", { email: formData.email })
      .then((data) => {
        if (data.status === true) {
          setSuccess(true);
        }
        if (data?.data?.status === 422) {
          setErrorVal("Email doesn't exist");
          reset();
        } else {
          setTimeout(() => {
            // window.history.pushState(
            //   `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL + "/auth-success" : "/auth-success"}`,
            //   "auth-login",
            //   `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL + "/auth-success" : "/auth-success"}`
            // );
            // window.location.reload();
          }, 2000);
        }
        setLoading(false);
      })
      .catch(() => {
        setError("email", "Email not exists");
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      <Head title="Forgot-Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          {success ? (
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle className="success" tag="h5">
                    Password Reset Email Sent
                  </BlockTitle>
                  <BlockDes>
                    <p>Please check your email address for instructions to reset your password.</p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
            </PreviewCard>
          ) : (
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h5">Reset password</BlockTitle>
                  <BlockDes>
                    <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
              {errorVal && (
                <div className="mb-3">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorVal}{" "}
                  </Alert>
                </div>
              )}
              <Form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                      Email
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="default-01"
                      name="email"
                      {...register("email", { required: "This field is required" })}
                      placeholder="Enter your email address or username"
                      className="form-control form-control-lg"
                    />
                    {errors.email && <span className="invalid">{errors.email.message}</span>}
                  </div>
                </div>
                <div className="form-group">
                  <Button size="lg" className="btn-block" type="submit" color="primary">
                    {loading ? <Spinner size="sm" color="light" /> : "Send Reset Link"}
                  </Button>
                </div>
              </Form>
              <div className="form-note-s2 text-center pt-4">
                <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                  <strong>Return to login</strong>
                </Link>
              </div>
            </PreviewCard>
          )}
          {/* <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">Reset password</BlockTitle>
                <BlockDes>
                  <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}{" "}
                </Alert>
              </div>
            )}
            <Form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    name="email"
                    {...register("email", { required: "This field is required" })}
                    placeholder="Enter your email address or username"
                    className="form-control form-control-lg"
                  />
                  {errors.email && <span className="invalid">{errors.email.message}</span>}
                </div>
              </div>
              <div className="form-group">
                <Button size="lg" className="btn-block" type="submit" color="primary">
                  {loading ? <Spinner size="sm" color="light" /> : "Send Reset Link"}
                </Button>
              </div>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Return to login</strong>
              </Link>
            </div>
          </PreviewCard> */}
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;
