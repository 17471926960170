import * as yup from "yup";

export const indicatorSchema = yup.object().shape({
  name: yup.string().required("Indicator Name is required"),
  description: yup.string(),
  is_woreda_level: yup.boolean().required("Indicator Woreda Level is required"),
  is_zone_level: yup.boolean().required("Indicator Zone Level is required"),
  is_region_level: yup.boolean().required("Indicator Region Level is required"),
  is_hf_level: yup.boolean().required("Indicator HF Level is required"),
  is_community_level: yup.boolean().required("Indicator Community Level is required"),
});
